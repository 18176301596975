#logo-loader.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow: hidden;

  background: radial-gradient(134.84% 202.54% at 99.3% 0%, rgba(98, 126, 234, 0.3) 3%, rgba(0, 0, 0, 0.02) 130%), #1c1d26;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#logo-loader.container.stopped * {
  animation-play-state: paused !important;
}

#logo-loader.container.light {
  background: #fff;
}

#logo-loader.container * {
  box-sizing: border-box;
}

#logo-loader.container.closing {
  opacity: 0;
  animation: closing 0.3s;
}

#logo-loader .center-positioned {
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  position: absolute;
}

#logo-loader .animation {
  position: absolute;
  border-radius: 50%;
  z-index: 2022;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
}

#logo-loader .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* background: white; */
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

#logo-loader .logo-container.playing {
  animation: logoBoxAnim 2s ease-in-out infinite;
}

@keyframes logoBoxAnim {
  0% {
    scale: 1;
  }
  35%,
  50% {
    scale: 0.8;
  }
  75% {
    scale: 1.15;
  }
  85%,
  100% {
    transition-timing-function: ease-in;
    scale: 1;
  }
}

#logo-loader .partner-logo {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}

#logo-loader .bg-round {
  border-radius: 50%;
  border: solid #ffffff10;
  z-index: 2002;
  width: 240px;
  height: 240px;
  border-width: 34px;
}

#logo-loader .bg-round.light {
  border-color: #6487ef1a;
}

#logo-loader .bg-round.playing {
  animation: borderBoxAnim 2s ease infinite;
}

@keyframes borderBoxAnim {
  0% {
    scale: 1;
  }
  40%,
  50% {
    scale: 1.2;
  }
  80% {
    scale: 0.9;
  }
  87% {
    scale: 1.08;
  }
  100% {
    scale: 1;
  }
}

#logo-loader .border-box {
  width: 145%;
  height: 145%;
}

#logo-loader .border-box svg {
  scale: -1 1;
  width: 100%;

  /* animation: borderAnimation 2s infinite; */
}

#logo-loader path.circle {
  will-change: stroke-dashoffset;
}

#logo-loader .arc1.playing {
  animation: borderAnimation 2s infinite;
}

#logo-loader .arc1.playing .circle {
  animation: circleAnimation 2s infinite;
}

#logo-loader .arc1.stopped .circle {
  animation: none;
  stroke-dashoffset: 207;
  opacity: 1;
}

#logo-loader .arc2.playing {
  animation: secondBorderAnimation 2s infinite;
}

#logo-loader .arc2.playing .circle {
  animation: circleAnimation2 2s infinite;
}

#logo-loader .arc3.playing {
  animation: thirdBorderAnimation 2s infinite;
}

#logo-loader .arc3.playing .circle {
  animation: circleAnimation3 2s infinite;
}

@keyframes borderAnimation {
  0% {
    rotate: 0deg;
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    rotate: 745deg;
  }
  82%,
  100% {
    opacity: 0;
  }
}

@keyframes secondBorderAnimation {
  0% {
    rotate: 0deg;
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  70% {
    opacity: 1;
    transition-timing-function: ease;
  }
  80% {
    rotate: 725deg;
  }
  82%,
  100% {
    opacity: 0;
  }
}

@keyframes thirdBorderAnimation {
  0% {
    rotate: 0deg;
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  70% {
    opacity: 1;
    transition-timing-function: ease;
  }
  80% {
    rotate: 705deg;
  }
  82%,
  100% {
    opacity: 0;
  }
}

@keyframes circleAnimation {
  0% {
    stroke-dashoffset: 620;
  }

  50% {
    stroke-dashoffset: 207;
  }

  70%,
  100% {
    stroke-dashoffset: 620;
  }
}

@keyframes circleAnimation2 {
  0% {
    stroke-dashoffset: 620;
  }

  50% {
    stroke-dashoffset: 241;
  }

  70%,
  100% {
    stroke-dashoffset: 620;
  }
}

@keyframes circleAnimation3 {
  0% {
    stroke-dashoffset: 620;
  }

  50% {
    stroke-dashoffset: 276;
  }

  70%,
  100% {
    stroke-dashoffset: 620;
  }
}

@keyframes closing {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  width: 65px;
  height: auto;
}
.logo {
  position: absolute;
}
.circle {
  position: absolute;
  width: 100%;
  height: auto;
  animation: rotateClockwise 1s infinite ease-in-out;
}
.check {
  width: 100%;
}

@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

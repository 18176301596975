.container {
  border: 0px;
  width: var(--toast-width);
  height: 103px;
  position: relative;
  margin-bottom: 7px;
  margin-top: 10px;
}
.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.columnWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 161px;
  max-width: 161px;
  padding-top: 17px;
  @media screen and (max-width: 373px) {
    width: fit-content;
    max-width: 140px;
  }
}
.upperText {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.lowerText {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 400;
  line-height: 100%;
}
.imageWrap {
  position: relative;
  height: 103px;
  width: 103px;
  min-height: 103px;
  min-width: 103px;
  padding: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 373px) {
    padding: 0px 10px;
    width: 90px;
    min-width: 90px;
  }
}
.imageWrap img {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  border-radius: 7px;
  object-fit: contain;
}

.complete {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: ease;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.icon {
  margin: 12px;
  margin-right: 20px;
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  margin-right: 3px;
}
.loader {
  width: 42px;
}
.mighty {
  border: var(--basic-border);
}
.mightyIcon {
  height: 42px;
  width: 42px;
}
.mightyIcon path:first-of-type {
  stroke: rgb(100, 136, 240);
}

.isPagePreload {
  z-index: 0 !important;

  @media screen and (max-width: 992px) {
    z-index: 200 !important;
  }
}

.fixed {
  position: fixed !important;
  z-index: 999999 !important;
}

.red {
  background-image: url('https://mighty-storage.fra1.digitaloceanspaces.com/static/images/leaderboardBg.webp') !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  span {
    color: var(--red-color) !important;
  }

  .mightyLogoImg {
    filter: drop-shadow(0 0 10px #f14f63) !important;
    display: block;
    position: relative;
  }
}

.wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: var(--body-color);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: opacify, transform;

  .start {
    position: absolute;
    width: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;

    background-image: url('./assets/grade-min.svg');
    background-size: 1310px 1310px;
    background-repeat: no-repeat;
    background-position: center center;
    height: 1310px;
    img {
      width: 521px;
      height: 593px;
      object-fit: contain;
    }

    @media screen and (max-width: 992px) {
      img {
        scale: 0.5;
      }
      background-size: 900px 900px;
      height: 800px;
    }

    @media screen and (max-width: 756px) {
      background-size: 500px 500px;
      height: 500px;
    }

    @media screen and (max-width: 560px) {
      img {
        scale: 0.4;
      }
      background-size: 370px 370px;
      height: 400px;
    }

    &.migration {
      animation: migration 6s infinite ease-in-out;
      @keyframes migration {
        0% {
          background-position: 275px 0;
          background-size: 1310px 1310px;
        }
        30% {
          background-position: 400px 400px;
          background-size: 900px 900px;
        }
        50% {
          background-position: 0 0;
          background-size: 1310px 1310px;
        }
        80% {
          background-position: 800px;
          background-size: 1100px 1100px;
        }
        100% {
          background-position: 275px 0;
          background-size: 1310px 1310px;
        }
      }
    }
  }

  .end {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 992px) {
      .bg1 {
        position: absolute;
        top: -540px;
        left: -540px;
      }

      .bg2 {
        position: absolute;
        bottom: -650px;
        right: -650px;
      }

      .bg3 {
        position: absolute;
        top: -430px;
        left: -430px;
      }
    }

    .mightyLogo {
      .mightyLogoImg {
        height: 81px;
        max-height: 81px;
      }
      p {
        font-size: 32px !important;
        font-weight: 700 !important;
        color: var(--font-color-strong) !important;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      img {
        width: 100%;
        max-width: 571px;
      }

      p {
        color: #6487ef;
        font-size: 20px;
        font-weight: 400;
        line-height: 118%;
        margin-top: 20px;
      }
    }
  }
}

.container {
  background: rgba(255, 255, 255, 0.096);
  border: none;
  height: 49px;
  border-radius: 39px;
  color: var(--font-color-strong);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  letter-spacing: 0.5px;
  white-space: pre;
  padding: 16px 37px;
}
.size_sm {
  padding: 0 15px;
  height: 36px;
}

.fullWidth {
  width: 100%;
}
.dark {
  background: rgb(37, 38, 47);
  color: var(--font-color);
}
.light {
  background: #626ee3;
  color: #ffffff;
}
.cool {
  color: #fdfdfd;
  background: var(--mighty-gradient-new);
}
.outline {
  border: var(--basic-border);
  background: none;
}
.gold {
  background: linear-gradient(90deg, #ffbf66 0%, #bb5844 100%);
  box-shadow: 8px 4px 9px 0 rgba(48, 49, 61, 0.01), 29px 18px 14px 0 rgba(48, 49, 61, 0.01), 45px 28px 15px 0 rgba(48, 49, 61, 0);
  color: #ffffff;
}
.white {
  background: var(--white-bg);
  box-shadow: var(--white-shadow);
  color: #131a29;
  height: 74px;
  font-size: 24px;
  line-height: 100%;
  padding: 16px 30px;
}
.container.loading {
  padding-top: 5px;
  padding-bottom: 5px;
}
.loader {
  width: auto !important;
  height: 100% !important;
}

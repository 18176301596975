.container {
  width: 340px;
  height: 136px;
  padding: 20px 22px;
  /*border: 1px solid rgba(255, 255, 255, 0.2);*/
  /*border-radius: 15px;*/
  display: flex;
  --friend-color: rgb(98, 126, 234);
  --battle-color: rgb(241, 79, 99);
}
.friend {
  background-image: url(./assets/bg/blueBg.svg);
}

.friend.remove {
  background-image: url(./assets/bg/redBg.svg);
}

.battle {
  background-image: url(./assets/bg/multiBg.svg);
}
.avatar {
  border-radius: 100%;
  height: 90px;
  width: 90px;
  max-height: 90px;
  max-width: 90px;
  min-height: 90px;
  min-width: 90px;
  margin-right: 16px;
}
.title {
  line-height: 125%;
  font-size: 14px;
}
.nickname {
  font-size: 16px;
  font-weight: 700;
  line-height: 125%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title div {
  color: #fff;
  font-size: 16px;
  line-height: 125%;
}
.buttons {
  display: flex;
  margin-top: 16px;
}
.buttons button {
  min-width: 0;
  padding: 8px 24px;
  height: 36px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
}
.buttons button:first-of-type {
  margin-right: 12px;
}

.buttons button:last-of-type {
  background: unset;
  opacity: 0.5;
}

.button {
  justify-content: flex-end;
  margin-top: unset;
}

.button button:first-of-type {
  margin-right: unset;
}

.maxW {
  max-width: 140px;
}

.full {
  width: 100%;
}

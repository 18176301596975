html {
  font-family: 'Rubik', sans-serif;
  font-size: 62.5%;
  width: 100vw;
  overflow-x: hidden;
}

html,
body {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: 0;
  margin: 0;

  background-color: var(--body-color);

  scroll-behavior: smooth;
}

#root {
  overflow: hidden;
}

body {
  font-size: 1.8rem;
}

p {
  line-height: 1.4;
}

h1 {
  font-size: 5.61rem;
}

h2 {
  font-size: 4.209rem;
}

h3 {
  font-size: 3.157rem;
}

h4 {
  font-size: 2.369rem;
}

h5 {
  font-size: 1.777rem;
}

h6 {
  font-size: 1.333rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  line-height: 1.1;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
}

@media screen and (max-width: 992px) {
  *,
  *::before,
  *::after {
    user-select: none;
  }
}

:root {
  --vh: 1vh;
  --window-primary-text: #fdfdfd;
  --window-secondary-text: #afb4b8;
  --window-third-text: var(--window-primary-text);
  --window-bg-button: rgba(255, 255, 255, 0.04);
  --window-primary-bg: #1c1d26;
}

:root .Toastify__toast-body,
:root .Toastify__toast-container,
:root .Toastify__toast-container > * {
  padding: 0;
  margin: 0;
}

:root .Toastify__toast-container {
  margin-top: 60px;
  width: var(--toast-width);
}

:root .Toastify__toast {
  margin-bottom: 10px;
}

:root .Toastify__toast-theme--light,
:root .Toastify__toast-theme--light {
  --toastify-color-light: none;
  background: none;
}

:root .Toastify__toast-body {
  margin-bottom: 10px;
}

:root .Toastify__toast {
  box-shadow: none;
}

:root .Toastify__progress-bar {
  --toastify-color-progress-success: var(--mighty-blue);
  --toastify-color-progress-info: var(--mighty-blue);
}

:root * {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

@media screen and (max-width: 992px) {
  .Toastify__toast-container {
    padding: 0 18px !important;
    left: 0;
    top: 43px;
    margin: 10px 0 0 0 !important;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast-container.isIOS {
    padding-top: 40px !important;
  }
}

input[type='text']:focus {
  /* box-shadow: 0 0 3px 1px var(--font-color); */
  outline: none;
}

.App {
  /* width: 100vw;*/
  max-width: 100vw;
  /* min-height: 100vh; */

  margin: 0;
  padding: 0;

  display: grid;
  grid-template: 138px 1fr / auto 1fr;

  transition: all 0.35s ease-in-out;
  position: relative;
}

.App.partner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.App.partner .navbar {
  opacity: 0;
}

.full-vh {
  height: calc(var(--vh, 1vh) * 100);
}

.glass-div {
  background: var(--transbg-color);
  background-blend-mode: overlay, normal;
}

.glass-div.rounded {
  border-radius: 22px;
}
.glass-div.light.linear {
  background: rgba(100, 135, 239, 0.15);
}
.glass-div.dark.linear {
  background: linear-gradient(76.31deg, rgba(42, 44, 59, 0.8) 5.23%, rgba(32, 33, 45, 0.8) 107.31%);
}

.glass-div.basic {
  background: rgb(var(--glass-bg--rgb));
}
.glass-div.basic.light.strong {
  background: #fdfdfd;
}

button {
  cursor: pointer;
}

button:disabled {
  opacity: 0.3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.default-bg {
  background-size: 100% 100%;
}

.default-bg.dark {
  background: radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(71, 75, 103, 0.4) 0%, rgba(0, 0, 0, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(76.31deg, rgba(147, 179, 255, 0.088) -1.86%, rgba(143, 168, 255, 0) 100.22%);
  background-blend-mode: normal, overlay;
}

.default-bg.light {
  background: #e6eafa;
}

.content-inners {
  height: fit-content;
  max-width: 100vw;
  width: 100%;
}

.fancy-border::before {
  content: '';

  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;

  border-radius: inherit;
  border: 1px solid var(--border-color);

  mix-blend-mode: overlay;
}

.fancy-border.bw--2::before {
  border-width: 2px;
}

.fancy-border.bw--3::before {
  border-width: 3px;
}

.fancy-border.bw--5::before {
  border-width: 5px;
}

.fancy-border.bw--10::before {
  border-width: 10px;
}

.fancy-border.br--10::before {
  border-radius: 10px;
}

.fancy-border.br--25::before {
  border-radius: 25px;
}

.fancy-border.common::before {
  border-color: var(--grade-light-font-common);
}

.bright--hover:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.filler {
  background: rgba(255, 255, 255, 0.15);
}

.filler.vertical {
  height: 70%;
  width: 1px;
}

.filler.horizontal {
  height: 1;
  width: 70%;
}

.radial-bg {
  background: linear-gradient(0deg, rgba(244, 248, 255, 0.56) -181.16%, rgba(193, 204, 244, 0.104) 50.52%), #2a2b37;
  background-blend-mode: normal, normal, overlay;
}

.radial-bg.cy {
  background: radial-gradient(76.84% 107.06% at -10.54% 103.64%, rgba(98, 234, 234, 0.16) 0%, rgba(13, 217, 217, 0) 100%),
    radial-gradient(60.13% 88.58% at 95.15% 27.04%, rgba(216, 222, 144, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(76.31deg, rgba(244, 248, 255, 0.01) 5.23%, rgba(193, 204, 244, 0.104) 107.31%);
}

.radial-bg.rc {
  background: radial-gradient(76.84% 107.06% at -10.54% 103.64%, rgba(234, 98, 98, 0.16) 0%, rgba(151, 13, 217, 0) 100%),
    radial-gradient(60.13% 88.58% at 95.15% 27.04%, rgba(144, 222, 217, 0.5) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(76.31deg, rgba(244, 248, 255, 0.01) 5.23%, rgba(193, 204, 244, 0.104) 107.31%);
}

.gray-button {
  min-width: 12.1rem;
  width: fit-content;
  height: 3.6rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;

  padding: 0px 16px 0 7px;

  border-radius: 22px;

  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1;

  border: none;

  color: #ffffff;
  transition: all 500ms ease-in-out;
  white-space: pre;
}

.gray-button.squashed {
  min-width: 0;
  width: 0;
  padding: 0;
  opacity: 0;
}

.gray-button.light {
  background: #626ee3;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0);
}

.gray-button.dark {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.08);
  color: #aaaaaa;
}

.mighty-colored {
  background: var(--mighty-gradient);
  color: var(--basic-dark);
  padding: 16px 37px;
  border-radius: 40px;
  color: white;
}

.hidden {
  opacity: 0;
  display: none !important;
}

.w-0 {
  width: 0;
}

.rotatable {
  transition: rotate 250ms ease-in-out;
}

.rotatable.active {
  rotate: 180deg;
}

.spinner {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.spinner > svg {
  width: auto;
  height: 100%;
  color: var(--font-color);
}

.play-button {
  min-width: 50px !important;
  min-height: 50px !important;
  width: 50px !important;
  height: 50px !important;
}

.play-button svg {
  height: 45%;
  min-width: 100%;
}

/* Animations */
@keyframes fadein {
  50% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
  }
}

@keyframes fadeinAppear {
  50% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeoutAppear {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeinLR {
  50% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(60px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes fadeoutLR {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-60px);
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1070px) {
  .content-inners {
    /* padding: 0 18px 0 0; */
  }
}

@media screen and (max-width: 992px) {
  .App {
    /* grid-template-rows: fit-content(100%) fit-content(100%); */
    /* grid-template-columns: 1fr; */
    /* grid-template-areas: */
    /* 'main' */
    /* 'footer'; */
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }

  .pwa-app .App {
    padding-top: env(safe-area-inset-top, 30px);
  }

  .content-inners {
    grid-area: main;
    height: fit-content;
    /* padding: 0 0 80px 0; */
    /* max-width: 720px; */
    /* width: 100%; */
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .content-inners {
    /* max-width: 540px; */
  }
}
@media screen and (max-width: 576px) {
  .content-inners {
    /* max-width: 100vw; */
  }
}

[data-tc-wallets-modal-container='true'] {
  z-index: 5000 !important;
}
